<template>
  <div>
    <ManagePromotion />
  </div>
</template>
<script>
import ManagePromotion from "@/components/Promotion/ManagePromotion";
export default {
  components: {
    ManagePromotion,
  },
  created() {
  },
};
</script>